var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component",
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "user-edit-component form-edit-component" },
        [_c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME })],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _vm.viewMode !== _vm.ViewModeType.VIEW
            ? _c(
                "jtl-button",
                {
                  attrs: {
                    type: "primary",
                    "click-prop": _vm.dialogOK.bind(this),
                  },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }