

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { UserEntityModel } from '@common-src/entity-model/user-entity';

@Component
export default class UserEditComponent extends FormDialogComponent<UserEntityModel> {

}
