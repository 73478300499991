






















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { UserEntityModel, UserResetPwdModel, UserQueryModel } from '@common-src/entity-model/user-entity';
import UserService, { UserResetPwdService } from '@common-src/service/user-v2';
import CommonService from '@common-src/service/common';
import UserEditComponent from './user-edit.vue';
import UserPwdComponent from './user-pwd.vue';
// import AddProjectUserDialog from '../org/org-user-add.vue';
import { ViewModeType } from '@common-src/model/enum';
import { UserStoreModule } from '@common-src/store/modules/user';
import OrgUserAddComponent from '../org/org-user-add.vue';
// import OrgUserAddComponent from '../org/org-user-add.vue';

@Component({
    components: {
        'user-edit-dialog': UserEditComponent,
        'user-pwd-dialog': UserPwdComponent,
        'add-project-user-dialog': OrgUserAddComponent
    }
})
export default class UserListComponent extends TableDialogFormComponent<UserEntityModel, UserQueryModel> {
    UserEntityModel = UserEntityModel;
    userService: UserService = new UserService();
    defaultPassword: string = null;
    created() {
        this.initTable({
            service: new UserService(),
            queryModel: new UserQueryModel(),
            tableColumns: _.filter(UserEntityModel.getTableColumns(), item => {
                if (this.IsProject) {
                    return item.dataIndex !== 'projectName';
                }
                return item;
            })
        });
        this.getList();
        !this.IsProject && (CommonService.getPageSettingInfo().then((res) => {
            this.defaultPassword = res.defaultPassword;
        }));
    }

    resetPwdClick(userModel: UserEntityModel) {
        const userPwdModel = new UserResetPwdModel();
        userPwdModel.id = userModel.id;
        (this.$refs.userPwdFormDialog as any).dialogOpen(userPwdModel, new UserResetPwdService(), ViewModeType.UPDATE);
        (this.$refs.userPwdFormDialog as any).dialogTitle = '重置密码';
    }

    deleteOrgClick(userModel: UserEntityModel) {
        const userId = userModel.id;
        this.userService.deleteOrgUser(userId).then(res => {
            this.showMessageSuccess('删除成功');
            this.getList();
        });
    }

    addProjectClick() {
        (this.$refs.addProjectUserDialog as OrgUserAddComponent).dialogOpen(null);
    }

    formDialogOK() {
        this.getList();
    }

    get IsProject() {
        return !!UserStoreModule.CurrentProject;
    }
}

